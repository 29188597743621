import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import { combineReducers } from 'redux'
import thunk from 'redux-thunk'

import showFingerIconReducer from './showFingerState/reducer'
import isFirstUserReducer from './isFirstUserState/reducer'
import eyesReducer from './eyesState/reducer'
import mapRecenterState from './mapRecenterState/reducer'

const rootReducer = combineReducers({
  showFIngerIcon: showFingerIconReducer,
  isFirstTimeUser: isFirstUserReducer,
  eyesState: eyesReducer,
  mapRecenter: mapRecenterState
})

const composeEnhancers = composeWithDevTools({})

export default preloadedState => {
  return createStore(
    rootReducer,
    preloadedState,
    composeEnhancers(applyMiddleware(thunk))
  )
}
