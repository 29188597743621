import { mapRecenterStateActionTypes } from './actions'
const { SAVE_COORDINATES, CLEAR_COORDINATES } = mapRecenterStateActionTypes

const mapRecenterState = (state = null, action) => {
  switch (action.type) {
    case SAVE_COORDINATES:
      return action.payload.coordinates
    case CLEAR_COORDINATES:
      return null
    default:
      return state
  }
}

export default mapRecenterState
