export const mapRecenterStateActionTypes = {
  SAVE_COORDINATES: 'SAVE_COORDINATES',
  CLEAR_COORDINATES: 'CLEAR_COORDINATES'
}

export const saveCoordinates = coordinates => ({
  type: 'SAVE_COORDINATES',
  payload: { coordinates }
})

export const clearCoordinates = {
  type: 'CLEAR_COORDINATES'
}
