import { canvasPoints } from '../../consts/canvasConsts'
import { eyesActionTypes } from './actions'

const eyesReducer = (state = canvasPoints, action) => {
  switch (action.type) {
    case eyesActionTypes.UPDATE_EYES_STATE:
      return action.payload.updatedState
    default:
      return state
  }
}

export default eyesReducer
