import { isFirstUserActionTypes } from './actions'

const {
  SET_IS_FIRST_TIME_USER,
  SET_IS_NOT_FIRST_TIME_USER
} = isFirstUserActionTypes

const initialState = { isFirstTime: true, checked: false }

const isFirstUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_IS_NOT_FIRST_TIME_USER:
      return { ...state, isFirstTime: false, checked: true }
    case SET_IS_FIRST_TIME_USER:
      return { ...state, isFirstTime: true, checked: true }
    default:
      return state
  }
}

export default isFirstUserReducer
