import React from 'react'
import GlobalStyle from './src/styled/globalStyle'
import 'scroll-behavior-polyfill'

import { Helmet } from 'react-helmet'

import eyeMainSvg from './src/images/eye-main.svg'
import eyeClosedSvg from './src/images/eye-closed.svg'
import eyeHoveredSvg from './src/images/eye-hovered.svg'

import grainBackgroundImage from './src/images/grain-background.png'

import PlexBold from './src/assets/fonts/IBMPlexSansCondensed-Bold.ttf'
import PlexRegular from './src/assets/fonts/IBMPlexSerif-Regular.ttf'
import PlexLight from './src/assets/fonts/IBMPlexSerif-Light.ttf'

import { version } from './package.json'

export const wrapPageElement = ({ element }) => {
  return (
    <>
      <Helmet>
        <link rel='preload' href={grainBackgroundImage} as='image' />
        <link
          rel='preload'
          href={PlexBold}
          as='font'
          type='font/woff2'
          crossOrigin
        />
        <link
          rel='preload'
          href={PlexLight}
          as='font'
          type='font/woff2'
          crossOrigin
        />

        <link
          rel='prefetch'
          href={PlexRegular}
          as='font'
          type='font/woff2'
          crossOrigin
        />
        <link rel='prefetch' href={eyeMainSvg} as='image' />
        <link rel='prefetch' href={eyeClosedSvg} as='image' />
        <link rel='prefetch' href={eyeHoveredSvg} as='image' />

        <meta name='app.version' content={version} />
      </Helmet>
      <GlobalStyle /> {element}
    </>
  )
}
