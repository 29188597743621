import { createGlobalStyle } from 'styled-components'
import PlexBold from '../assets/fonts/IBMPlexSansCondensed-Bold.ttf'
import PlexRegular from '../assets/fonts/IBMPlexSerif-Regular.ttf'
import PlexLight from '../assets/fonts/IBMPlexSerif-Light.ttf'

import { removeBackgroundOnClick } from './extends'
import { siteBreakPoints, colors } from '../consts/styleConsts'

const { medium, small } = siteBreakPoints

const { dark } = colors.background

const GlobalStyle = createGlobalStyle`
  * {margin: 0; padding: 0; box-sizing: border-box;}
  body, html {
    height: 100%;
    min-height: 100%;
    background: ${dark};
    font-size: 22px;
    @media screen and (max-width: ${medium}px) {
      font-size: 18px;
    }
    @media screen and (max-width: ${small}px) {
      font-size: 16px;
    }
  }
  ul {
    list-style-type: none;
    margin: 0;
  }
  a {
    text-decoration: none;
    ${removeBackgroundOnClick};
  }
  body::-webkit-scrollbar, html::-webkit-scrollbar {
    display: none;
  }
  body, html {
    scrollbar-width: none;
    -ms-overflow-style: none;
  }

  @font-face {
    font-family: 'Plex-Bold';
    src: local('Plex-Bold'), url(${PlexBold}) format('truetype');
  }

  @font-face {
    font-family: 'Plex';
    src: local('Plex'), url(${PlexRegular}) format('truetype');
  }

  @font-face {
    font-family: 'Plex-Light';
    src: local('Plex-Light'), url(${PlexLight}) format('truetype');
  }
`
export default GlobalStyle
