import { navigate } from 'gatsby'

export const eyesActionTypes = {
  UPDATE_EYES_STATE: 'UPDATE_EYES_STATE'
}

const updateEyesState = updatedState => ({
  type: 'UPDATE_EYES_STATE',
  payload: { updatedState }
})

// Thunk actions / Effects
export const onSetEyeToViewed = eyeName => (dispatch, getState) => {
  const { eyesState } = getState()
  const copyEyesState = { ...eyesState }
  copyEyesState[eyeName].viewed = true
  saveEyesStateLocalStorage(copyEyesState)
  dispatch(updateEyesState(copyEyesState))
  areAllEyesViewed(copyEyesState)
}
export const checkLocalStorageEyesState = () => (dispatch, getState) => {
  const { eyesState } = getState()
  const storageEyesState = getEyesStateFromLocalStorage()
  const isSame = JSON.stringify(eyesState) === JSON.stringify(storageEyesState)
  if (storageEyesState && !isSame) {
    dispatch(updateEyesState(storageEyesState))
  }
}

// Helper functions
const saveEyesStateLocalStorage = eyesState =>
  localStorage.setItem('conceptik-eyesState', JSON.stringify(eyesState))

const getEyesStateFromLocalStorage = () => {
  const storageEyesState = localStorage.getItem('conceptik-eyesState')
  return storageEyesState ? JSON.parse(storageEyesState) : null
}
const saveLearnedSoMuchPageViewedLocalStorage = () => {
  localStorage.setItem(
    'conceptik-learned-so-much-page-viewed',
    JSON.stringify(true)
  )
}
const getLearnedSoMuchPageViewedLocalStorage = () => {
  const isLearnedSoMuchViewed = localStorage.getItem(
    'conceptik-learned-so-much-page-viewed'
  )
  return isLearnedSoMuchViewed ? JSON.parse(isLearnedSoMuchViewed) : null
}

const areAllEyesViewed = eyesState => {
  const notViewedCount = Object.values(eyesState).filter(v => !v.viewed).length
  const isLearnedSoMuchViewed = getLearnedSoMuchPageViewedLocalStorage()
  if (notViewedCount === 0 && !isLearnedSoMuchViewed) {
    saveLearnedSoMuchPageViewedLocalStorage()
    navigate('/you-learned-so-much')
  }
}
