// Generate Canvas Points. Cordinates are in %
const canvasPoints = {
  // Center
  A: {
    pos: { x: 50, y: 50 },
    relations: ['H', 'I', 'J', 'M', 'N', 'P', 'K'],
    viewed: true,
    clicked: true
  },
  // Outer circle
  B: {
    pos: { x: 86, y: 10 },
    relations: ['H', 'C', 'I', 'J'],
    viewed: false,
    clicked: false
  },
  C: {
    pos: { x: 95, y: 58 },
    relations: ['B', 'J', 'K', 'L', 'D'],
    viewed: false,
    clicked: false
  },
  D: {
    pos: { x: 86, y: 91 },
    relations: ['C', 'L', 'M', 'E'],
    viewed: false,
    clicked: false
  },
  E: {
    pos: { x: 25, y: 90 },
    relations: ['D', 'M', 'N', 'O', 'F'],
    viewed: false,
    clicked: false
  },
  F: {
    pos: { x: 5, y: 68 },
    relations: ['G', 'N', 'O', 'E'],
    viewed: false,
    clicked: false
  },
  G: {
    pos: { x: 7, y: 25 },
    relations: ['H', 'P', 'O', 'F'],
    viewed: false,
    clicked: false
  },
  H: {
    pos: { x: 34, y: 8 },
    relations: ['G', 'P', 'I', 'B'],
    viewed: false,
    clicked: false
  },
  // Inner circle
  I: {
    pos: { x: 58, y: 19 },
    relations: ['H', 'B', 'J', 'K', 'P'],
    viewed: false,
    clicked: false
  },
  J: {
    pos: { x: 74, y: 36 },
    relations: ['I', 'B', 'C', 'K'],
    viewed: false,
    clicked: false
  },
  K: {
    pos: { x: 69, y: 55 },
    relations: ['I', 'J', 'L', 'M'],
    viewed: false,
    clicked: false
  },
  L: {
    pos: { x: 76, y: 67 },
    relations: ['K', 'C', 'D', 'M'],
    viewed: false,
    clicked: false
  },
  M: {
    pos: { x: 55, y: 80 },
    relations: ['N', 'K', 'L', 'D', 'E'],
    viewed: false,
    clicked: false
  },
  N: {
    pos: { x: 34, y: 65 },
    relations: ['A', 'F', 'O', 'P', 'M', 'E'],
    viewed: false,
    clicked: false
  },
  O: {
    pos: { x: 22, y: 52 },
    relations: ['G', 'P', 'N', 'E', 'F'],
    viewed: false,
    clicked: false
  },
  P: {
    pos: { x: 29, y: 40 },
    relations: ['G', 'H', 'I', 'N', 'O'],
    viewed: false,
    clicked: false
  }
}

const canvasDots = {
  dotSize: 10,
  dotColor: '#fff'
}

const canvasLines = {
  lineWidth: 1,
  lineColor: 'rgba(107, 107, 107, 0.2)'
}

export { canvasPoints, canvasDots, canvasLines }
