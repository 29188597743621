// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-components-posts-related-post-page-post-page-js": () => import("./../src/components/PostsRelated/PostPage/PostPage.js" /* webpackChunkName: "component---src-components-posts-related-post-page-post-page-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-intro-js": () => import("./../src/pages/intro.js" /* webpackChunkName: "component---src-pages-intro-js" */),
  "component---src-pages-sources-js": () => import("./../src/pages/sources.js" /* webpackChunkName: "component---src-pages-sources-js" */),
  "component---src-pages-what-you-can-do-js": () => import("./../src/pages/what-you-can-do.js" /* webpackChunkName: "component---src-pages-what-you-can-do-js" */),
  "component---src-pages-you-learned-so-much-js": () => import("./../src/pages/you-learned-so-much.js" /* webpackChunkName: "component---src-pages-you-learned-so-much-js" */)
}

