export const isFirstUserActionTypes = {
  SET_IS_NOT_FIRST_TIME_USER: 'SET_IS_NOT_FIRST_TIME_USER',
  SET_IS_FIRST_TIME_USER: 'SET_IS_FIRST_TIME_USER'
}

const setIsNotFirstTimeUser = {
  type: 'SET_IS_NOT_FIRST_TIME_USER'
}
const setIsFirstTimeUser = {
  type: 'SET_IS_FIRST_TIME_USER'
}

// Thunk actions / Effects
export const checkIfFirstTimeUser = dispatch => {
  const localStorageData = localStorage.getItem('conceptik-firstTimeUser')
  if (localStorageData) {
    return dispatch(setIsNotFirstTimeUser)
  } else {
    return dispatch(setIsFirstTimeUser)
  }
}

export const setFirstTimeUserFalse = dispatch => {
  localStorage.setItem('conceptik-firstTimeUser', 'notFirstTime')
  return dispatch(setIsNotFirstTimeUser)
}
