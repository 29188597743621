export const colors = {
  primary: '#4b7e9b',
  grey: {
    darkGrey1: '#232828',
    darkGrey2: '#767676',
    grey: '#a4a4a4',
    grey2: '#a9a9a9',
    grey3: '#979797',
    lightGrey: '#dedede'
  },
  secondary: {
    lightBlue: '#6ab6f3',
    orange: '#ff820c'
  },
  background: {
    dark: '#1e272c',
    dark2: `rgba(42, 52, 58, 0.7)`,
    dark3: '#2a343a',
    dark4: '#212626'
  }
}

export const zIndex = {
  particlesIndex: 1,
  canvasLinesIndex: 2,
  textIndex: 3,
  eyesIndex: 3,
  mapFinger: 4,
  headerIndex: 5,
  modalIndex: 6
}

export const siteBreakPoints = {
  xxLarge: 1600,
  xLarge: 1200,
  large: 992,
  medium: 768,
  small: 576,
  xSmall: 320,

  heightLarge: 812,
  heightMedium: 736,
  heightSmall: 667
}
