const showFingerIconReducer = (state = true, action) => {
  switch (action.type) {
    case 'HIDE_FINGER_ICON':
      return false
    default:
      return state
  }
}

export default showFingerIconReducer
